input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 0px; /* Adjust the size as needed */
  height: 0px; /* Adjust the size as needed */
  background: #000;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  width: 0px; /* Adjust the size as needed */
  height: 0px; /* Adjust the size as needed */
  background: #000;
  cursor: pointer;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  width: 0px; /* Adjust the size as needed */
  height: 0px; /* Adjust the size as needed */
  background: #000;
  cursor: pointer;
}
